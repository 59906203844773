import DocumentoService from "lirida-main-js-service/service/Documento/DocumentoService";
import Documento from "lirida-main-js-service/service/Documento/Documento";
import Usuario from "lirida-main-js-service/service/Usuario/Usuario";
import React, { Component } from "react";
import Util from "../../../util/Util";
import Container from "../sistema-components/Container/Container";
import Header from "../sistema-components/Header/Header";
import Sidebar from "../sistema-components/Sidebar/Sidebar";
import "../sistema.css";
import { FaArrowLeft, FaPen, FaTrash } from "react-icons/fa";
import Constante from "../../../util/Constante";
import Pesquisa from "lirida-main-js-service/service/Pesquisa/Pesquisa";
import PessoaService from "lirida-main-js-service/service/Pessoa/PessoaService";
import TipoDocumentoProcessoService from "lirida-main-js-service/service/TipoDocumentoProcesso/TipoDocumentoProcessoService";
import DepartamentoService from "lirida-main-js-service/service/Departamento/DepartamentoService";
import TipoDocumentoProcesso from "lirida-main-js-service/service/TipoDocumentoProcesso/TipoDocumentoProcesso";
import TabelaPrecoService from "lirida-main-js-service/service/TabelaPreco/TabelaPrecoService";
import DocumentoItem from "lirida-main-js-service/service/Documento/DocumentoItem";
import TabelaPreco from "lirida-main-js-service/service/TabelaPreco/TabelaPreco";
import MsgOK from "../../../component/MsgOK/MsgOK";
import PopUp from "../../../component/PopUp/PopUp";
import Dropdown from "../../../component/Dropdown/Dropdown";
import Loading from "../../../component/Loading/Loading";
import TipoRelacionamentoService from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamentoService';
import TipoRelacionamento from 'lirida-main-js-service/service/TipoRelacionamento/TipoRelacionamento';
import DocumentoRelacionamento from 'lirida-main-js-service/service/DocumentoRelacionamento/DocumentoRelacionamento';
import DocumentoCaracteristicaDocumentoService from 'lirida-main-js-service/service/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumentoService';
import TipoArquivo from 'lirida-main-js-service/service/TipoArquivo/TipoArquivo';
import DocumentoArquivo from 'lirida-main-js-service/service/DocumentoArquivo/DocumentoArquivo';
import TipoArquivoService from 'lirida-main-js-service/service/TipoArquivo/TipoArquivoService';
import Input from "../../../component/Input/Input";
import TipoAtendimento from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimento';
import DocumentoAtendimento from 'lirida-main-js-service/service/DocumentoAtendimento/DocumentoAtendimento';
import TipoAtendimentoService from 'lirida-main-js-service/service/TipoAtendimento/TipoAtendimentoService';

export default class Pedido extends Component {
  util = new Util();
  tipoDocumentoProcessoService = new TipoDocumentoProcessoService();
  documentoService = new DocumentoService();
  pessoaService = new PessoaService();
  documentoCaracteristicaDocumentoService = new DocumentoCaracteristicaDocumentoService();
  tipoRelacionamentoService = new TipoRelacionamentoService();
  tabelaPrecoService = new TabelaPrecoService();
  constante = new Constante();
  departamentoService = new DepartamentoService();
  tipoArquivoService = new TipoArquivoService();
  tipoAtendimentoService = new TipoAtendimentoService();

  state = {
    usuario: new Usuario(),
    documento: new Documento(),
    documentoItem: new DocumentoItem(),
    quantidade: 1,
    filtro: "com filtro",
    listaPedido: [],
    listaPessoa: [],
    listaTabelaPreco: [],
    listaDocumentoItem: [],
    tabelaPreco: new TabelaPreco(),
    urlBaseImagemItem: undefined,
    tela: "1",
    conteudo: "",
    listaTipoDocumentoProcesso: [],
    mensagemVisivel: false,
    mensagem: "",
    popupVisivel: false,
    mensagemExcluir: "",
    dropdownVisivel: false,
    listaTipoDocumentoProcessoItem: [],
    tipoDocumentoProcesso: new TipoDocumentoProcesso(),
    listaDepartamento: [],
    carregando: false,
    tag: "Local_Realização_Serviço",
    listaDocumentoRelacionamento: [],
    listaPessoaDoRelacionamento: [],
    documentoRelacionamento: new DocumentoRelacionamento(),
    tipoRelacionamento: new TipoRelacionamento(),
    listaTipoRelacionamento: [],
    popupDeletarDocumentoRelacionamentoVisivel: false,
    listaCaracteristicaDocumento: [],
    listaTipoArquivo: [],
    listaDocumentoArquivo: [],
    documentoArquivo: new DocumentoArquivo(),
    tipoArquivo: new TipoArquivo(),
    popupDeletarDocumentoArquivoVisivel: false,
    listaDocumentoAtendimento: [],
    listaTipoAtendimento: [],
    documentoAtendimento: new DocumentoAtendimento(),
    tipoAtendimento: new TipoAtendimento(),
  };

  async componentDidMount() {
    await this.iniciar();
  }

  async iniciar() {
    let urlBaseImagemItem = await this.util.buscarLocalStorage(
      "URL_BASE_IMAGEM_ITEM"
    );
    let usuario = await this.util.buscarLocalStorage("USUARIO");

    this.setState({
      usuario: usuario,
      urlBaseImagemItem: urlBaseImagemItem,
    });

    let parametros = this.buscarParametrosUrl();

    if (parametros) {
      // muda tela
    }

    let retornoDepartamento = await this.departamentoService.listar(
      this.constante.seqUsuario
    );

    let retornoTipoDocumentoProcesso =
      await this.tipoDocumentoProcessoService.listarTipoDocumentoProcesso(
        this.constante.seqTipoDocumento
      );
    let retornoPessoa = await this.pessoaService.listar(
      usuario.seqUsuario,
      this.state.conteudo
    );
    let retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(
      this.constante.seqTabelaPreco
    );

    console.log(retornoTabelaPreco);
    this.setState({
      listaPessoa: retornoPessoa.objeto,
      listaPessoaDoRelacionamento: retornoPessoa.objeto,
      listaTipoDocumentoProcesso: retornoTipoDocumentoProcesso.objeto,
      tipoDocumentoProcesso: retornoTipoDocumentoProcesso.objeto[0],
      listaTabelaPreco: retornoTabelaPreco.objeto,
      listaDepartamento: retornoDepartamento.objeto,
    });
    console.log(retornoPessoa);
    console.log("******** departamento *********");
    console.log(retornoDepartamento);

    this.listar();
    this.listarTipoRelacionamento();
    this.listarTipoArquivo();
    this.listarTipoAtendimento();

  }

  async listarTipoAtendimento() {
    let retorno = await this.tipoAtendimentoService.listar(this.constante.seqUsuario)
    this.setState({ listaTipoAtendimento: retorno.objeto });
  }

  async listarTipoArquivo() {
    let retorno = await this.tipoArquivoService.listar(this.constante.seqUsuario)
    this.setState({ listaTipoArquivo: retorno.objeto });
  }

  async listarTipoRelacionamento() {
    let retorno = await this.tipoRelacionamentoService.listar(this.constante.seqUsuario);
    this.setState({ listaTipoRelacionamento: retorno.objeto })
  }

  async listarCaracteristicaDocumento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    console.log("********************")
    console.log(documento)

    let retorno = await this.documentoCaracteristicaDocumentoService.listar(documento);
    this.setState({
      listaCaracteristicaDocumento: retorno.objeto,
    })

    console.log(retorno)
  }

  marcarDesmarcarCaracteristica(pIndex) {
    let listaCaracteristicaDocumento = this.state.listaCaracteristicaDocumento;

    if (listaCaracteristicaDocumento[pIndex].marcado == "true") {
      listaCaracteristicaDocumento[pIndex].marcado = "false";
    } else if (listaCaracteristicaDocumento[pIndex].marcado == "false") {
      listaCaracteristicaDocumento[pIndex].marcado = "true";
    }

    listaCaracteristicaDocumento[pIndex].seqDocumento = this.state.documento.seqDocumento;

    this.setState({
      listaCaracteristicaDocumento: listaCaracteristicaDocumento,
    })
  }

  async salvarCaracteristica() {
    let retorno = await this.documentoCaracteristicaDocumentoService.salvar(this.state.listaCaracteristicaDocumento);
    console.log(retorno);

    if (retorno.resultado === true) {
      this.setState({
        msgOKVisivel: true,
        mensagem: retorno.msg
      })
    }

    if (retorno.resultado === false) {
      this.setState({
        msgErrorVisivel: true,
        mensagem: retorno.msg
      })
    }

    setTimeout(
      () => this.setState({ msgOKVisivel: false, msgErrorVisivel: false })
      , 5000
    )
  }



  formatarData(pData) {
    console.log(pData);
    let dataSplitada = pData?.split("-");
    let data = new Date(dataSplitada[0], dataSplitada[1], dataSplitada[2])
      .toLocaleString()
      .split(" ")
      .join("T")
      .replace("/", "-")
      .replace("/", "-");

    console.log(data);
    return data;
  }

  buscarParametrosUrl() {
    if (!window.location.search) {
      return null;
    }

    let query = window.location.search.slice(1);
    let partes = query.split("&");
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split("=");
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });

    return data;
  }

  criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }

  async listar(pTipoDocumentoProcesso) {
    let tipoDocumentoProcesso = pTipoDocumentoProcesso;

    if (!pTipoDocumentoProcesso) {
      tipoDocumentoProcesso = this.state.tipoDocumentoProcesso;
    }

    let listaPesquisa = [
      this.criarObjetoPesquisa(
        "documento.seq_usuario_vendedor",
        this.state.usuario.seqUsuario,
        "",
        "NUMERO"
      ),
      this.criarObjetoPesquisa(
        "documento.seq_tipo_documento_processo",
        tipoDocumentoProcesso.seqTipoDocumentoProcesso,
        "AND",
        "NUMERO"
      ),
    ];

    let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
    this.setState({
      listaPedido: retorno.objeto,
    });

    console.log(retorno.objeto);
  }

  async listarDocumentoItem(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
      documento
    );
    this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
    console.log(retornoDocumentoItem);
  }

  async salvar() {
    let documento = this.state.documento;
    documento.seqUsuario = this.constante.seqUsuario;
    documento.seqUsuarioVendedor = this.state.usuario.seqUsuario;
    documento.dataProgramada = documento.dataProgramada;
    documento.seqTipoDocumento = this.constante.seqTipoDocumento;

    console.log(documento);

    let retorno = await this.documentoService.salvar(documento);
    console.log(retorno);

    this.setState({
      mensagemVisivel: true,
      mensagem: "Informações salvas com sucesso!",
    });
  }

  selecionarDocumentoItem(pItem) {
    console.log(pItem);
    this.setState({
      documentoItem: pItem,
      quantidade: pItem.quantidade,
      mensagemVisivel: false,
    });
  }

  async novo() {
    let documento = new Documento();
    this.setState({
      documentoItem: new DocumentoItem(),
    });

    documento.seqUsuario = this.constante.seqUsuario;
    documento.seqUsuarioVendedor = this.state.usuario.seqUsuario;
    documento.seqTipoDocumento = this.constante.seqTipoDocumento;

    let retornoDocumento = await this.documentoService.novo(documento);
    console.log(retornoDocumento);
    this.setState({
      documento: retornoDocumento.objeto,
      tela: "2",
    });

    this.listarDocumentoItem();
  }

  async deletar() {
    await this.documentoService.deletar(this.state.documento);
    this.setState({ modalExcluirVisivel: false, tela: "1" });
    this.listar();
  }

  async deletarItem() {
    await this.documentoService.deletarItem(this.state.documentoItem);
    this.setState({
      documentoItem: new DocumentoItem(),
      popupVisivel: false,
    });
    this.listarDocumentoItem();
  }

  selecionar(pDocumento) {
    let documento = pDocumento;

    this.setState({
      dropdownVisivel: false,
      mensagemVisivel: false,
      documento: documento,
      tela: "2",
    });

    this.listarDocumentoItem(documento);
    this.listarTipoDocumentoProcessoItem(documento.seqTipoDocumentoProcesso);
    this.listarDocumentoRelacionamento(documento);
    this.listarCaracteristicaDocumento(documento);
    this.listarDocumentoArquivo(documento);
    this.listarDocumentoAtendimento(documento);

  }

  buscarTipoAtendimento(pSeqTipoAtendimento) {
    let listaTipoAtendimento = this.state.listaTipoAtendimento;
    console.log(pSeqTipoAtendimento)

    console.log(listaTipoAtendimento)

    for (let i = 0; i < listaTipoAtendimento.length; i++) {
      if (listaTipoAtendimento[i].seqTipoAtendimento === Number(pSeqTipoAtendimento)) {
        this.setState({
          tipoAtendimento: listaTipoAtendimento[i]
        })
        console.log(listaTipoAtendimento[i])
      }
    }
  }

  selecionarDocumentoAtendimento(pDocumentoAtendimento) {
    let documentoAtendimento = pDocumentoAtendimento;
    this.setState({
      documentoAtendimento: documentoAtendimento,
    })
    this.buscarTipoAtendimento(documentoAtendimento.seqDocumentoAtendimento);
  }

  novoDocumentoAtendimento() {
    this.tipoAtendimento = this.state.tipoAtendimento;
    this.tipoAtendimento = new TipoAtendimento();
    console.log(this.tipoAtendimento.seqTipoAtendimento);


    this.setState({
      documentoAtendimento: new DocumentoAtendimento(),
      tipoAtendimento: new TipoAtendimento(),
    })
  }


  async listarDocumentoAtendimento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoListaDocumentoAtendimento = await this.documentoService.listarAtendimento(documento);
    this.setState({
      listaDocumentoAtendimento: retornoListaDocumentoAtendimento.objeto
    })
    console.log(retornoListaDocumentoAtendimento.objeto);
  }

  alerta_deletarDocumentoAtendimento() {
    this.setState({
      popupDeletarDocumentoAtendimentoVisivel: true
    })
  }

  async deletarDocumentoAtendimento() {
    await this.documentoService.deletarAtendimento(this.state.documentoAtendimento);

    this.setState({
      documentoAtendimento: new DocumentoAtendimento(),
      popupDeletarDocumentoAtendimentoVisivel: false,
    })

    this.novoDocumentoAtendimento();
    this.listarDocumentoAtendimento();
  }


  async salvarDocumentoAtendimento() {
    let documentoAtendimento = this.state.documentoAtendimento;
    documentoAtendimento.seqDocumento = this.state.documento.seqDocumento;
    documentoAtendimento.seqUsuario = this.state.documento.seqUsuario;

    console.log(documentoAtendimento)
    await this.documentoService.salvarAtendimento(documentoAtendimento);

    this.novoDocumentoAtendimento();
    this.listarDocumentoAtendimento();
  }

  alerta_deletarDocumentoArquivo(pItem) {
    this.setState({
      documentoArquivo: pItem,
      popupDeletarDocumentoArquivoVisivel: true
    });
  }


  selecionarDocumentoArquivo(pDocumentoArquivo) {
    let documentoArquivo = pDocumentoArquivo;
    this.setState({
      documentoArquivo: documentoArquivo,
    })
    this.buscarTipoArquivo(documentoArquivo.seqTipoDocumentoArquivo);
  }



  async listarTipoDocumentoProcessoItem(pSeqTipoDocumentoProcesso) {
    let retorno =
      await this.tipoDocumentoProcessoService.listarTipoDocumentoProcessoItem(
        pSeqTipoDocumentoProcesso
      );
    this.setState({ listaTipoDocumentoProcessoItem: retorno.objeto });
    console.log(retorno.objeto);
  }

  selecionarTabelaPreco(pSeqItem) {
    console.log(pSeqItem);
    let listaTabelaPreco = this.state.listaTabelaPreco;
    let tabelaPreco = new TabelaPreco();

    for (let i = 0; i < listaTabelaPreco.length; i++) {
      if (listaTabelaPreco[i].seqItem === Number(pSeqItem)) {
        tabelaPreco = listaTabelaPreco[i];
        console.log(tabelaPreco);
      }
    }

    this.setState({
      tabelaPreco: tabelaPreco,
      mensagemVisivel: false,
    });
  }

  selecionarPessoa(pSeqPessoa) {
    console.log(pSeqPessoa);
    let listaPessoa = this.state.listaPessoa;
    let documento = this.state.documento;

    for (let i = 0; i < listaPessoa.length; i++) {
      if (listaPessoa[i].seqPessoa === Number(pSeqPessoa)) {
        documento.seqPessoa = listaPessoa[i].seqPessoa;
      }
    }

    console.log(documento);

    this.setState({
      documento: documento,
      mensagemVisivel: false,
    });
  }

  async listarPessoaRelacionamento(pPessoa) {
    let pessoa;

    if (pPessoa) {
      pessoa = pPessoa;
    } else {
      pessoa = this.state.pessoa;
    }

    let retornoListaPessoaRelacionamento = await this.pessoaService.listarRelacionamento(pessoa);
    this.setState({
      listaPessoaRelacionamento: retornoListaPessoaRelacionamento.objeto
    })
    console.log(retornoListaPessoaRelacionamento.objeto);
  }


  mostrarMensagem(pTipo, pMensagem) {
    if (pTipo === "0") {
    }

    if (pTipo === "1") {
      this.setState({
        mensagemVisivel: true,
        mensagem: pMensagem,
      });
    }

    if (pTipo === "2") {
    }
  }

  async salvarItem() {
    let documentoItem = new DocumentoItem();

    if (this.state.documentoItem.seqDocumento) {
      documentoItem = this.state.documentoItem;
    }

    if (this.state.tabelaPreco.seqItem) {
      documentoItem.seqItem = this.state.tabelaPreco.seqItem;
    }
    documentoItem.quantidade = this.state.quantidade;
    documentoItem.seqDocumento = this.state.documento.seqDocumento;

    let retorno = await this.documentoService.salvarItem(documentoItem);
    console.log(retorno);

    this.listarDocumentoItem();
    this.setState({
      mensagemVisivel: true,
      mensagem: retorno.msg,
      documentoItem: new DocumentoItem(),
      tabelaPreco: new TabelaPreco(),
      quantidade: 1,
    });
  }

  async deletar() {
    await this.documentoService.deletar(this.state.documento);
    this.setState({
      popupExcluirDocumentoVisivel: false,
      tela: "1",
    });
    this.listar();
  }

  mudarStatusDocumentoProcesso(pStatus) {
    let documento = this.state.documento;
    documento.seqTipoDocumentoProcesso =
      pStatus.seqTipoDocumentoProcessoDestino;

    this.documentoService.salvar(documento);

    this.setState({
      dropdownVisivel: false,
      tela: "1",
    });

    this.listar();
  }

  async selecionarTipoDocumentoProcesso(pItem) {
    this.setState({ carregando: true, listaTipoDocumentoProcessoItem: [] });
    let tipoDocumentoProcesso = pItem;
    this.setState({ tipoDocumentoProcesso: tipoDocumentoProcesso });
    await this.listar(tipoDocumentoProcesso);
    this.setState({ carregando: false });
  }


  // DOCUMENTO RELACIONAMENTO

  buscarTipoRelacionamento(pSeqTipoRelacionamento) {
    let listaTipoRelacionamento = this.state.listaTipoRelacionamento;
    console.log(pSeqTipoRelacionamento)

    for (let i = 0; i < listaTipoRelacionamento.length; i++) {
      if (listaTipoRelacionamento[i].seqTipoRelacionamento === Number(pSeqTipoRelacionamento)) {
        this.setState({
          tipoRelacionamento: listaTipoRelacionamento[i]
        })
        console.log(listaTipoRelacionamento[i])
      }
    }
  }

  async listarDocumentoRelacionamento(pDocumento) {
    let documento;

    if (pDocumento) {
      documento = pDocumento;
    } else {
      documento = this.state.documento;
    }

    let retornoListaDocumentoRelacionamento = await this.documentoService.listarRelacionamento(documento);
    this.setState({
      listaDocumentoRelacionamento: retornoListaDocumentoRelacionamento.objeto
    })
    console.log(retornoListaDocumentoRelacionamento.objeto);
  }

  novoDocumentoRelacionamento() {
    this.setState({
      documentoRelacionamento: new DocumentoRelacionamento(),
      tipoRelacionamento: new TipoRelacionamento(),
    })
  }

  async salvarDocumentoRelacionamento() {
    let documentoRelacionamento = this.state.documentoRelacionamento;
    documentoRelacionamento.seqDocumento = this.state.documento.seqDocumento;
    documentoRelacionamento.seqUsuario = this.state.documento.seqUsuario;

    console.log(documentoRelacionamento);
    await this.documentoService.salvarRelacionamento(documentoRelacionamento);

    this.novoDocumentoRelacionamento();
    this.listarDocumentoRelacionamento();
  }

  alerta_deletarDocumentoRelacionamento() {
    this.setState({
      popupDeletarDocumentoRelacionamentoVisivel: true
    })
  }

  async deletarDocumentoRelacionamento() {
    await this.documentoService.deletarRelacionamento(this.state.documentoRelacionamento);

    this.setState({
      documentoRelacionamento: new DocumentoRelacionamento(),
      popupDeletarDocumentoRelacionamentoVisivel: false,
    })

    this.novoDocumentoRelacionamento();
    this.listarDocumentoRelacionamento();
  }

  selecionarDocumentoRelacionamento(pDocumentoRelacionamento) {
    let documentoRelacionamento = pDocumentoRelacionamento;
    this.setState({
      documentoRelacionamento: documentoRelacionamento,
    })
    this.buscarTipoRelacionamento(documentoRelacionamento.seqTipoRelacionamento);
  }

  // CRUD ARQUIVO 

  novoDocumentoArquivo() {
    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      tipoArquivo: new TipoArquivo(),
    })
  }

  async deletarDocumentoArquivo() {
    await this.documentoService.deletarArquivo(this.state.documentoArquivo);

    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      popupDeletarDocumentoArquivoVisivel: false,
    })

    this.novoDocumentoArquivo();
    this.listarDocumentoArquivo();
  }

  async selecionarArquivo(pEvento) {
    this.arquivo = pEvento.target.files[0];
    console.log(this.arquivo)

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        this.setState({
          documentoArquivo: {
            ...this.state.documentoArquivo,
            arquivo: reader.result.split(',')[1]
          }
        })
      },
      false
    );

    if (this.arquivo) {
      reader.readAsDataURL(this.arquivo);
      this.setState({
        documentoArquivo: {
          ...this.state.documentoArquivo,
          arquivoOriginal: this.arquivo.name
        }
      })
    }
  }

  buscarTipoArquivo(pSeqTipoArquivo) {
    let listaTipoArquivo = this.state.listaTipoArquivo;
    console.log(pSeqTipoArquivo)

    for (let i = 0; i < listaTipoArquivo.length; i++) {
      if (listaTipoArquivo[i].seqTipoArquivo === Number(pSeqTipoArquivo)) {
        this.setState({
          tipoArquivo: listaTipoArquivo[i]
        })
        console.log(listaTipoArquivo[i])
      }
    }
  }

  async salvarDocumentoArquivo() {
    this.documentoArquivo = this.state.documentoArquivo;
    this.documentoArquivo.tipoArquivoNome = this.state.tipoArquivo.nome;
    this.documentoArquivo.seqTipoArquivo = this.state.tipoArquivo.seqTipoArquivo;
    this.documentoArquivo.seqDocumento = this.state.documento.seqDocumento;
    console.log(this.documentoArquivo)
    await this.documentoService.salvarArquivo(this.documentoArquivo);

    this.setState({
      documentoArquivo: new DocumentoArquivo(),
      tipoArquivo: new TipoArquivo(),
    })

    this.listarDocumentoArquivo()
  }

  async listarDocumentoArquivo(pDocumento) {
    if (pDocumento) {
      this.documento = pDocumento;
    } else {
      this.documento = this.state.documento;
    }
    this.listaDocumentoArquivo = await this.documentoService.listarArquivo(this.documento);
    this.setState({ listaDocumentoArquivo: this.listaDocumentoArquivo.objeto });
    console.log(this.listaDocumentoArquivo.objeto)
  }

  render() {
    return (
      <>
        {this.state.tela === "1" ? (
          <Container>
            <Header selecionado="pedido" />
            <div className="sistema-wrapper-lista">
              <Sidebar>
                <nav
                  style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  {this.state.listaDepartamento.map((itemDepartamento) => (
                    <div className="sistema-sidebar-pedido-filtro">
                      <h1 style={{ marginTop: "10px" }}>
                        {itemDepartamento.nome}
                      </h1>
                      {this.state.listaTipoDocumentoProcesso.map(
                        (itemTipoDocumentoProcesso) =>
                          itemTipoDocumentoProcesso.seqDepartamento ===
                          itemDepartamento.seqDepartamento && (
                            <button
                              onClick={() =>
                                this.selecionarTipoDocumentoProcesso(
                                  itemTipoDocumentoProcesso
                                )
                              }
                              className={`btn-lg ${this.state.tipoDocumentoProcesso ===
                                itemTipoDocumentoProcesso
                                ? "btn-com-fundo"
                                : "btn-secundario"
                                }`}
                            >
                              {itemTipoDocumentoProcesso?.nome}
                            </button>
                          )
                      )}
                    </div>
                  ))}
                </nav>
              </Sidebar>
              <div className="sistema-conteudo-wrapper">
                <section className="sistema-input-wrapper">
                  <h1>Pedido</h1>

                  <div className="sistema-form-e-botao-wrapper"></div>

                  <div className="sistema-input-wrapper-botao-wrapper">
                    <button
                      onClick={() => this.novo()}
                      className="btn btn-com-fundo"
                    >
                      Novo
                    </button>
                  </div>
                </section>

                <table className="sistema-table">
                  <thead className="sistema-thead">
                    <tr className="sistema-tr-head">
                      <th>Id</th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Opção</th>
                    </tr>
                  </thead>
                  <tbody className="sistema-tbody" style={{ maxHeight: 535 }}>
                    {this.state.listaPedido.map((item) => (
                      <>
                        <tr className="sistema-tr-body">
                          <td className="sistema-td">{item.seqDocumento}</td>
                          <td className="sistema-td">{item.pessoaNome}</td>
                          <td className="sistema-td">{item.pessoaEmail}</td>
                          <td className="sistema-td">
                            <button
                              onClick={() => this.selecionar(item)}
                              className="btn btn-icone btn-com-fundo"
                            >
                              <FaPen />
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>

                {this.state.carregando ? (
                  <Loading />
                ) : this.state.listaPedido.length === 0 &&
                  !this.state.carregando ? (
                  <p style={{ textAlign: "center" }}>
                    Nenhuma informação encontrada
                  </p>
                ) : null}
              </div>
            </div>
          </Container>
        ) : this.state.tela === "2" ? (
          <>

            <PopUp
              visivel={this.state.popupDeletarDocumentoArquivoVisivel}
              msg={"Deseja realmente excluir o arquivo " + this.state.documentoArquivo.arquivoOriginal + " ?"}
              aoConfirmar={() => this.deletarDocumentoArquivo()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoArquivoVisivel: false, })}
            />


            <PopUp
              visivel={this.state.popupDeletarDocumentoRelacionamentoVisivel}
              msg={"Deseja realmente excluir o relacionamento com " + this.state.documentoRelacionamento.pessoaRelacionadaNome + " ?"}
              aoConfirmar={() => this.deletarDocumentoRelacionamento()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoRelacionamentoVisivel: false, })}
            />

            <PopUp
              visivel={this.state.popupExcluirDocumentoVisivel}
              msg={this.state.mensagemExcluir}
              aoConfirmar={() => this.deletar()}
              aoCancelar={() =>
                this.setState({ popupExcluirDocumentoVisivel: false })
              }
            />

            <PopUp
              visivel={this.state.popupVisivel}
              msg={this.state.mensagemExcluir}
              aoConfirmar={() => this.deletarItem()}
              aoCancelar={() => this.setState({ popupVisivel: false })}
            />
            <PopUp
              visivel={this.state.popupDeletarDocumentoAtendimentoVisivel}
              msg={"Deseja realmente excluir o atendimento " + this.state.documentoAtendimento.tipoAtendimentoNome + " ?"}
              aoConfirmar={() => this.deletarDocumentoAtendimento()}
              aoCancelar={() => this.setState({ popupDeletarDocumentoAtendimentoVisivel: false, })}
            />

            <Container>
              <div className="sistema-wrapper-detalhe">
                <Header selecionado="pedido" />

                <section className="sistema-input-wrapper">
                  <MsgOK
                    visivel={this.state.mensagemVisivel}
                    msg={this.state.mensagem}
                  />
                  <div className="sistema-detalhe-voltar">
                    <button
                      onClick={() => {
                        this.setState({
                          tela: "1",
                          mensagemVisivel: false,
                        });
                        this.listar();
                      }}
                      className="btn btn-icone btn-com-fundo"
                    >
                      <FaArrowLeft />
                    </button>

                    <h1>Requisição de serviços </h1>

                    <div className="sistema-detalhe-botao">
                      {this.state.documento.seqPessoa &&
                        this.state.documento.vendedorNome ? (
                        <button
                          onClick={() =>
                            this.setState({
                              popupExcluirDocumentoVisivel: true,
                              mensagemExcluir:
                                "Deseja realmente excluir o pedido de " +
                                this.state.documento.pessoaNome +
                                " ?",
                            })
                          }
                          className="btn btn-excluir"
                        >
                          Excluir
                        </button>
                      ) : null}
                      <button
                        onClick={() => this.salvar()}
                        className="btn btn-com-fundo"
                      >
                        Salvar
                      </button>

                      {this.state.documento.seqPessoa &&
                        this.state.documento.vendedorNome ? (
                        <div className="btn-dropdown-wrapper">
                          <button
                            onClick={() =>
                              this.setState({
                                dropdownVisivel: !this.state.dropdownVisivel,
                              })
                            }
                            className="btn btn-com-fundo"
                          >
                            Enviar para
                          </button>

                          <Dropdown visivel={this.state.dropdownVisivel}>
                            {this.state.listaTipoDocumentoProcessoItem.map(
                              (item) => (
                                <button
                                  onClick={() =>
                                    this.mudarStatusDocumentoProcesso(item)
                                  }
                                  className="dropdown-option"
                                >
                                  {item.destino}
                                </button>
                              )
                            )}
                          </Dropdown>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <form className="sistema-form-wrapper">
                    <section>
                      <label>Razão social</label><br />
                      {this.state.documento.pessoaNome ? (
                        <h1>{this.state.documento.pessoaNome}</h1>
                      ) : (
                        <select
                          className="input"
                          onChange={(pEvento) =>
                            this.selecionarPessoa(pEvento.target.value)
                          }
                        >
                          <option value={null}>Selecione o cliente</option>
                          {this.state.listaPessoa.map((item) => (
                            <option value={item.seqPessoa}>{item.nome}</option>
                          ))}
                        </select>
                      )}
                    </section>
                    <section>
                      <label>Data programada</label>
                      <br />
                      <input
                        type="date"
                        onChange={(pTexto) =>
                          this.setState({
                            documento: {
                              ...this.state.documento,
                              dataProgramada: pTexto.target.value,
                            },
                          })
                        }
                        value={this.state.documento.dataProgramada}
                      />
                    </section>
                  </form>

                  <form className="sistema-form-wrapper">
                    <section>
                      {/* secao de input */}

                      <label>CPF/CNPJ </label>
                      <br />
                      {/* {this.state.documento.pessoaNome ?
                                                    <h1>{this.state.documento.pessoaNome}</h1> : */}
                      <select
                        className="input"
                        onChange={(pEvento) =>
                          this.selecionarPessoa(pEvento.target.value)
                        }
                      >
                        <option value={null}>Selecione o documento</option>
                        {/* {this.state.listaPessoa.map(item => (
                                                            <option value={item.seqPessoa}>{item.nome}</option>
                                                        ))} */}
                      </select>
                    </section>

                    <section>
                      <label>Endereço</label>
                      <br />
                      <input placeholder="Rua: xxxxxxxx" />
                    </section>
                  </form>
                  <form className="sistema-form-wrapper">
                    <section>
                      <label>telefone</label>
                      <br />
                      <input type="tel" placeholder="(x) xxxxx-xxxx" />
                    </section>
                    <section>
                      <label>Cep</label>
                      <br />
                      <input placeholder="xxxxx-xxx" />
                    </section>
                    <section>
                      <label>Municipio</label>
                      <br />
                      <input placeholder="xxxxxxxx" />
                    </section>
                    <section>
                      <label>UF</label>
                      <br />
                      <input placeholder="xx" />
                    </section>
                    <section>
                      <label>Email</label>
                      <br />
                      <input type="email" placeholder="email@gmail.com" />
                    </section>
                  </form>
                </section>

                <div className="sistema-estrutura-cliente">
                  <div className="sistema-sidebar-detalhe">
                    <button
                      onClick={() => this.setState({ tag: "dados_serviços" })}
                      className={`btn btn-lg ${this.state.tag === "dados_serviços"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Dados do serviço</button>
                    <button
                      onClick={() => this.setState({ tag: 'Caracteristicas' })}
                      className={`btn btn-lg ${this.state.tag === 'Caracteristicas' ?
                        'btn-com-fundo' :
                        'btn-secundario'
                        }`}>Características</button>
                    <button
                      onClick={() => this.setState({ tag: "Relacionamentos" })}
                      className={`btn btn-lg ${this.state.tag === "Relacionamentos"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Relacionamentos</button>
                    <button
                      onClick={() => this.setState({ tag: "Arquivos_anexados" })}
                      className={`btn btn-lg ${this.state.tag === "Arquivos_anexados"
                        ? "btn-com-fundo"
                        : "btn-secundario"
                        }`}
                    >Arquivos anexados</button>
                    <button
                      onClick={() => this.setState({ tag: 'Atendimento' })}
                      className={`btn btn-lg ${this.state.tag === 'Atendimento' ?
                        'btn-com-fundo' :
                        'btn-secundario'
                        }`}>Atendimento</button>

                  </div>

                  <div className="sistema-campo-detalhe">

                    {this.state.tag === "dados_serviços" ? (
                      <>

                        <div
                          className='sistema-campo-detalhe-form'>
                          <h1> Dados do serviço - NOVO </h1>
                          <div className='sistema-campo-detalhe-input-wrapper-cliente'>
                            <div>
                              <label>Item</label><br />
                              <select
                                style={{ width: '100%' }}
                                className="input">
                                <option>

                                </option>
                              </select>
                            </div>

                            <div>
                              <label>Quantidade</label><br />
                              <select
                                style={{ width: '100%' }}
                                className="input">
                                <option>

                                </option>
                              </select>
                            </div>
                          </div>
                          <button
                            onClick={() => this.salvarEndereco()}
                            className='btn btn-com-fundo'>Confirmar</button>
                        </div>

                        <table>
                          <thead className="sistema-thead">
                            <tr className="sistema-tr-head">
                              <th>Imagem</th>
                              <th>Nome</th>
                              <th>Qntd.</th>
                              <th>Valor unit.</th>
                              <th>Valor total</th>
                              <th>Detalhe</th>
                            </tr>
                          </thead>

                          <tbody
                            style={{
                              maxHeight: 250,
                            }}
                            className="sistema-tbody"
                          >
                            {this.state.listaDocumentoItem.map((item) => (
                              <tr className="sistema-tr-body">
                                <td>
                                  <img
                                    src={this.state.urlBaseImagemItem + item.itemImagem}
                                    className="sistema-imagem-tabela-preco-item"
                                  />
                                </td>
                                <td>{item.itemNomeVitrine}</td>
                                <td>{item.quantidade}</td>
                                <td>data</td>
                                <td>data</td>
                                <td>
                                  <button
                                    onClick={() => this.selecionarDocumentoItem(item)}
                                    className="btn btn-icone btn-com-fundo"
                                  >
                                    <FaPen />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : null}

                    {this.state.tag === 'Caracteristicas' ?
                      <div
                        className='sistema-campo-detalhe-form'>
                        <div className='sistema-campo-detalhe-input-wrapper'>
                          {this.state.listaCaracteristicaDocumento.map((item, index) => (
                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                              <input
                                onChange={() => this.marcarDesmarcarCaracteristica(index)}
                                checked={item.marcado === "true" ? true : false}
                                type="checkbox"
                                style={{ width: 15, height: 15 }} />
                              <label>{item.nome}</label>
                            </div>
                          ))}
                        </div>
                        <button
                          onClick={() => this.salvarCaracteristica()}
                          className='btn btn-com-fundo'>Salvar</button>
                      </div>
                      : null}

                    {this.state.tag === 'Relacionamentos' ?
                      <>
                        <div
                          className='sistema-campo-detalhe-form'>
                          <div className='sistema-campo-detalhe-input-wrapper-cliente'>
                            <div>
                              <label>Pessoa</label><br />

                              <select
                                style={{ width: '100%' }}
                                value={this.state.documentoRelacionamento.seqPessoaRelacionada}
                                onChange={pEvento => this.setState({
                                  documentoRelacionamento: {
                                    ...this.state.documentoRelacionamento,
                                    seqPessoaRelacionada: Number(pEvento.target.value)
                                  }
                                })}
                                className='input'>

                                <option selected={this.state.documentoRelacionamento.seqPessoaRelacionada === undefined ?
                                  true : false} value={null}>Selecione uma pessoa</option>

                                {this.state.listaPessoaDoRelacionamento.map((item) => (
                                  <option
                                    selected={
                                      this.state.documentoRelacionamento.seqPessoaRelacionada === item.seqPessoa ?
                                        true : false
                                    }
                                    value={item.seqPessoa}>{item.nome}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label>Tipo de relacionamento</label><br />

                              <select
                                style={{ width: '100%' }}
                                value={this.state.documentoRelacionamento.seqTipoRelacionamento}
                                onChange={pEvento => this.setState({
                                  documentoRelacionamento: {
                                    ...this.state.documentoRelacionamento,
                                    seqTipoRelacionamento: Number(pEvento.target.value)
                                  }
                                })}
                                className='input'>

                                <option selected={this.state.documentoRelacionamento.seqTipoRelacionamento === undefined ?
                                  true : false} value={null}>Selecione um tipo de relacionamento</option>

                                {this.state.listaTipoRelacionamento.map((item) => (
                                  <option
                                    selected={
                                      this.state.tipoRelacionamento.seqTipoRelacionamento === item.seqTipoRelacionamento ?
                                        true : false
                                    }
                                    value={item.seqTipoRelacionamento}>{item.nome}</option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <label>Situação</label><br />
                              <select
                                value={this.state.documentoRelacionamento.situacao}
                                onChange={pEvento => this.setState({
                                  documentoRelacionamento: {
                                    ...this.state.documentoRelacionamento,
                                    situacao: pEvento.target.value
                                  }
                                })}
                                style={{ width: '100%' }}
                                className='input'>
                                <option value="ATIVO">ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                              </select>
                            </div>

                          </div>
                          <div className='sistema-campo-detalhe-button-wrapper'>
                            {this.state.documentoRelacionamento.seqDocumentoRelacionamento ?
                              <button
                                onClick={() => this.alerta_deletarDocumentoRelacionamento()}
                                className='btn btn-secundario'
                                style={{
                                  borderColor: 'red',
                                  color: 'red'
                                }}
                              >
                                Excluir
                              </button>
                              : null}
                            {this.state.documentoRelacionamento.seqDocumentoRelacionamento ? <button
                              onClick={() => this.novoDocumentoRelacionamento()}
                              className='btn btn-secundario'>Cancelar</button> : null}

                            <button
                              onClick={() => this.salvarDocumentoRelacionamento()}
                              className='btn btn-com-fundo'>Salvar</button>
                          </div>
                        </div>

                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Pessoa</th>
                              <th>Tipo de Relacionamento</th>
                              <th>Situação</th>
                              <th>Dt. Cadastro</th>
                              <th>Detalhe</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 370 }}>
                            {this.state.listaDocumentoRelacionamento.map(item => (
                              <tr className='sistema-tr-body'>
                                <td className='sistema-td'>{item.PessoaRelacionadaNome}</td>
                                <td className='sistema-td'>{item.tipoRelacionamentoNome}</td>
                                <td className='sistema-td'>{item.situacao}</td>
                                <td className='sistema-td'>{item.dataCadastro}</td>
                                <td className='sistema-td'>
                                  <button
                                    onClick={() => this.selecionarDocumentoRelacionamento(item)}
                                    className='btn btn-icone btn-com-fundo'>
                                    <FaPen />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}

                    {this.state.tag === 'Arquivos_anexados' ?
                      <>
                        <div
                          className='sistema-campo-detalhe-form'>
                          <div className='sistema-campo-detalhe-input-wrapper-cliente'>
                            <div>
                              <label>Tipo de arquivo</label><br />
                              <select
                                style={{ width: '100%' }}
                                value={this.state.tipoArquivo.seqTipoArquivo}
                                onChange={pTexto => this.buscarTipoArquivo(pTexto.target.value)}
                                className='input'>

                                <option selected={this.state.tipoArquivo.seqTipoArquivo === undefined ?
                                  true : false} value={null}>Selecione um tipo de arquivo</option>

                                {this.state.listaTipoArquivo.map((item) => (
                                  <option
                                    selected={
                                      this.state.tipoArquivo.seqTipoArquivo === item.seqTipoArquivo ?
                                        true : false
                                    }
                                    value={item.seqTipoArquivo}>{item.nome}</option>
                                ))}
                              </select>
                            </div>

                            <Input
                              titulo="Selecione o arquivo"
                              onChange={pEvento => this.selecionarArquivo(pEvento)}
                              type="file"
                            />
                          </div>

                          <button className='btn btn-com-fundo' onClick={() => this.salvarDocumentoArquivo()}>Salvar</button>
                        </div>
                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Nome</th>
                              <th>Tipo de Arquivo</th>
                              <th>Dt. Cadastro</th>
                              <th>Ação</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 440 }}>
                            {this.state.listaDocumentoArquivo.map(item => (
                              <tr className='sistema-tr-body'>
                                <td className='sistema-td'>{item.arquivoOriginal}</td>
                                <td className='sistema-td'>{item.tipoArquivoNome}</td>
                                <td className='sistema-td'>{item.dataCadastro}</td>
                                <td className='sistema-td'>
                                  <button
                                    onClick={() => this.alerta_deletarDocumentoArquivo(item)}
                                    className='btn btn-icone btn-com-fundo'>
                                    <FaTrash />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}

                    {this.state.tag === 'Atendimento' ?
                      <>
                        <div
                          className='sistema-campo-detalhe-form'>
                          <div className='sistema-campo-detalhe-input-wrapper-atendimento'>
                            <div>
                              <label>Tipo de atendimento</label><br />
                              <select
                                style={{ width: '100%' }}
                                value={this.state.documentoAtendimento.seqTipoAtendimento}
                                onChange={pEvento => this.setState({
                                  documentoAtendimento: {
                                    ...this.state.documentoAtendimento,
                                    seqTipoAtendimento: Number(pEvento.target.value)
                                  }
                                })}
                                className='input'>

                                <option selected={this.state.documentoAtendimento.seqTipoAtendimento === undefined ?
                                  true : false} value={null}>Selecione um tipo de atendimento</option>

                                {this.state.listaTipoAtendimento.map((item) => (
                                  <option
                                    selected={
                                      this.state.tipoAtendimento.seqTipoAtendimento === item.seqTipoAtendimento ?
                                        true : false
                                    }
                                    value={item.seqTipoAtendimento}>{item.nome}</option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <label>Situação</label><br />
                              <select
                                value={this.state.documentoAtendimento.situacao}
                                onChange={pEvento => this.setState({
                                  documentoAtendimento: {
                                    ...this.state.documentoAtendimento,
                                    situacao: pEvento.target.value
                                  }
                                })}
                                style={{ width: '100%' }}
                                className='input'>
                                <option value="ATIVO">ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <Input
                              style={{ width: '100%' }}
                              onChange={pTexto => this.setState({
                                documentoAtendimento: {
                                  ...this.state.documentoAtendimento,
                                  descricao: pTexto.target.value
                                }
                              })}
                              value={this.state.documentoAtendimento.descricao || ''}
                              titulo="Descrição"
                            />
                          </div>
                          <div className='sistema-campo-detalhe-button-wrapper'>
                            {this.state.documentoAtendimento.seqDocumentoAtendimento ?
                              <button
                                onClick={() => this.alerta_deletarDocumentoAtendimento()}
                                className='btn btn-secundario'
                                style={{
                                  borderColor: 'red',
                                  color: 'red'
                                }}
                              >
                                Excluir
                              </button>
                              : null}
                            {this.state.documentoAtendimento.seqDocumentoAtendimento ? <button
                              onClick={() => this.novoDocumentoAtendimento()}
                              className='btn btn-secundario'>Cancelar</button> : null}

                            <button
                              onClick={() => this.salvarDocumentoAtendimento()}
                              className='btn btn-com-fundo'>Salvar</button>
                          </div>
                        </div>

                        <table className='sistema-table'>
                          <thead className='sistema-thead'>
                            <tr className='sistema-tr-head'>
                              <th>Tipo de Atendimento</th>
                              <th>Descricao</th>
                              <th>Dt. Cadastro</th>
                              <th>Detalhe</th>
                            </tr>
                          </thead>
                          <tbody
                            className='sistema-tbody'
                            style={{ maxHeight: 480 }}>
                            {this.state.listaDocumentoAtendimento.map(item => (
                              <tr className='sistema-tr-body'>
                                <td className='sistema-td'>{item.tipoAtendimentoNome}</td>
                                <td className='sistema-td'>{item.descricao}</td>
                                <td className='sistema-td'>{item.dataCadastro}</td>
                                <td className='sistema-td'>
                                  <button
                                    onClick={() => this.selecionarDocumentoAtendimento(item)}
                                    className='btn btn-icone btn-com-fundo'>
                                    <FaPen />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                      : null}

                  </div>
                </div>
              </div>
            </Container>
          </>
        ) : null}
      </>
    );
  }
}
