import React, { Component } from 'react';
import computadores from '../../../../assets/image/computadorpromo.png';
import './convite.css';

export default class Convite extends Component {
    render() {
        return (
            <section id="convite-container">
                <section className="convite-wrapper">
                    <h1 className='convite-titulo-alternativo'>A <span className='convite-titulo-alternativo'>maior plataforma</span> de <span className='convite-titulo'>serviços </span>de <span className='convite-titulo'>instalação</span> do Brasil</h1>
                    <img src={computadores} alt="computadores, tablet, celular" className='convite-imagem'/>
                </section>
            </section>
        )
    }
}