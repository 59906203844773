import { Component } from "react"
import Container from "../sistema-components/Container/Container"

export default class ResumoOS extends Component{
    render(){
        return(
            <Container>
                <div>

                </div>
            </Container>

        )
    }
}