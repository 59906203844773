import React, { Component } from 'react';
import logo from '../../assets/image/logo.png';

export default class TelaMapa extends Component {

    render() {
        return <div style={{ width: '100vw', height: '100vh', }}>
            <div style={{ display: 'flex', flexDirection:'row', alignItems:'center' }}>
                <img style={{marginLeft:'auto',}}src={logo} alt="logo iex brasil" />
                <h1 style={{ fontSize: 30, marginRight:'auto' }}>CENTRAL DE SERVIÇOS</h1>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d17653388.58829892!2d-46.174662540816925!3d-15.767386640551429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1648067449287!5m2!1spt-BR!2sbr" width="100%" height="100%"></iframe>
            {/* 
            <Map
                key='AIzaSyAgSkIShc6gbPWp_vqttiVRnuuHqWQT5yA'
                google={this.props.google}
                zoom={14}
                style={{
                    width: '100%',
                    height: '100%'
                }}
                initialCenter={
                    {
                        lat: -1.2884,
                        lng: 36.8233
                    }
                }
            /> */}
            {/* <img src="https://maps.googleapis.com/maps/api/staticmap?center=-22.753522740132485,-43.47941187378065&zoom=14&size=150x150&key=AIzaSyAgSkIShc6gbPWp_vqttiVRnuuHqWQT5yA"></img> */}
        </div>
    }
}