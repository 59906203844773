import React, { Component } from 'react';
import './sobre.css';

export default class Sobre extends Component {
    render() {
        return (
            <section id='sobre-container'>
                <div className='sobre-wrapper'>
                    <section className='sobre-conteudo-container'>
                        <h1 className='sobre-titulo'>Quem somos</h1><br />
                        <p className='sobre-texto'>IEX BRASIL é a plataforma que faz a conexão entre cliente e um técnico credenciado. Garantindo qualidade e segurança na sua instalação.</p>
                    </section>
                    <section className='sobre-conteudo-container'>
                        <h1 className='sobre-titulo'>Como funciona</h1><br />
                        <p className='sobre-texto'>Com pequenos passos você terá um instalador credenciado para realizar sua instalação: Crie sua conta, informe sua localização e chame um instalador.
                        </p>
                    </section>
                    <section className='sobre-conteudo-container'>
                        <h1 className='sobre-titulo'>Para quem</h1><br />
                        <p className='sobre-texto'>Clientes, Fabricantes, Técnicos. Feito para todos aqueles que precisam de um serviço de instalação, IEX BRASIL.</p>
                    </section>
                </div>
            </section>
        )
    }
}