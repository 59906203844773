import React, { Component } from 'react';
import './popup.css';

export default class PopUp extends Component {
    render() {
        return (
            this.props.visivel ?
                <div className='popup-container'>
                    <section className='popup-wrapper'>
                        <h1>{this.props.msg}</h1>

                        <div className='popup-botao-wrapper'>
                            {this.props.aoConfirmar ? <button
                                onClick={this.props.aoConfirmar}
                                className={`btn ${this.props.aoCancelar ? '' : 'btn-com-fundo'}`}>{this.props.txtConfirmar ? this.props.txtConfirmar : 'Sim'}</button> : null}
                            {this.props.aoCancelar ? <button
                                onClick={this.props.aoCancelar}
                                className='btn btn-com-fundo'>{this.props.txtCancelar ? this.props.txtCancelar : 'Não'}</button> : null}
                        </div>
                    </section>
                    <button className='popup-fundo' onClick={this.props.aoCancelar} />
                </div>
                : null
        )
    }
}