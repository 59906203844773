import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/image/logo.png';
import './header.css'
import { MdAppSettingsAlt, MdChat, MdPerson, MdMenu } from 'react-icons/md';
import { BsTextRight } from 'react-icons/bs';
import { FaHandshake } from 'react-icons/fa';
import Dropdown from '../../../../component/Dropdown/Dropdown';

export default class Header extends Component {

    state = {
        dropdownVisivel: false,
    }

    render() {
        return (
            <header id='header-container'>
                <div className='header-wrapper'>
                    <img src={logo} alt="logo" className='logo-principal' />

                    <nav>
                        <div className='header-nav-icone-wrapper'>
                            <a href="#funcionalidade-container"><MdAppSettingsAlt /></a>
                            <a href="#prova-social-container"><MdChat /></a>
                            <a href="#sobre-container"><BsTextRight /></a>
                            <a href="#parceiros-container"><FaHandshake /></a>
                        </div>

                        <div className='header-nav-wrapper'>
                            <a href="#sobre-container">Sobre</a>
                            <a href="#funcionalidade-container">Funcionalidade</a>
                            <a href="#prova-social-container">Depoimentos</a>
                            <a href="#parceiros-container">Parceiros</a>
                        </div>
                    </nav>

                    <Link to="/login" className='header-nav-login-wrapper'><MdPerson />Login</Link>

                    <div className='header-menu-icone-wrapper'>
                        <div className='btn-dropdown-wrapper'>
                            <button
                                onClick={() => this.setState({ dropdownVisivel: !this.state.dropdownVisivel })}
                            >
                                <MdMenu size={30} />
                            </button>
                            <Dropdown
                                visivel={this.state.dropdownVisivel}
                            >
                                <a href="#sobre-container">Sobre</a>
                                <a href="#funcionalidade-container">Funcionalidade</a>
                                <a href="#prova-social-container">Depoimentos</a>
                                <a href="#parceiros-container">Parceiros</a>

                                <Link to="/login" className='header-nav-login-wrapper'><MdPerson />Login</Link>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}