import React, { Component } from 'react';
import Input from '../../../component/Input/Input';
import Container from '../sistema-components/Container/Container';
import Header from '../sistema-components/Header/Header';
import Sidebar from '../sistema-components/Sidebar/Sidebar';
import '../sistema.css';

export default class Financeiro extends Component {
    render() {
        return (
            <Container>
                <Header selecionado="financeiro" />
                <div className='sistema-wrapper-lista'>
                    <Sidebar bgi="bgi-financeiro">
                    </Sidebar>
                    <div className='sistema-conteudo-wrapper'>
                        <section className='sistema-input-wrapper'>
                            <h1>Financeiro</h1>

                            <div className='sistema-form-e-botao-wrapper'>
                                <Input
                                    titulo="Data inicial"
                                    type="date"
                                />
                                <Input
                                    titulo="Data final"
                                    type="date"
                                />
                                <Input
                                    titulo="Data programada inicial"
                                    type="date"
                                />
                                <Input
                                    titulo="Data programada final"
                                    type="date"
                                />
                            </div>

                            <div className='sistema-input-wrapper-botao-wrapper'>
                                <button
                                    className='btn btn-com-fundo'
                                >Buscar</button>
                            </div>
                        </section>

                        <table className='sistema-table'>
                            <thead className='sistema-thead'>
                                <tr className='sistema-tr-head'>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Opção</th>
                                </tr>
                            </thead>
                            <tbody
                                className='sistema-tbody'
                                style={{ maxHeight: 535 }}>
                                {/* {this.state.listaPedido.map(item => (
                                    <tr className='sistema-tr-body'>
                                        <td className='sistema-td'>{item.nome}</td>
                                        <td className='sistema-td'>{item.emailPlataformaLirida}</td>
                                        <td className='sistema-td'>
                                            <button
                                                onClick={() => this.selecionar(item)}
                                                className='btn btn-icone btn-com-fundo'>
                                                <FaPen />
                                            </button>
                                        </td>
                                    </tr>
                                ))} */}
                            </tbody>
                        </table>

                    </div>
                </div>
            </Container>
        )
    }
}