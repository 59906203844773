import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/image/logo.png';
import './header.css';
import Util from '../../../../util/Util';

export default class Header extends Component {
    util =  new Util();

    async removerUsuario(){
       await this.util.removerLocalStorage('USUARIO')
       await this.util.removerLocalStorage('PESSOA')
    }

    render() {
        return (
            <header className='sistema-header-container'>
                <img src={logo} alt="logo iex brasil" className='sistema-header-logo-principal'/>

                <nav className='sistema-header-nav'>
                    <Link
                        style={
                            this.props.selecionado === 'dashboard' ?
                                { color: '#FF872c', fontWeight: 'bold' } :
                                null
                        }
                        to="/dashboard" className="sistema-header-nav-link">
                        Dashboard
                    </Link>

                    <Link
                        style={
                            this.props.selecionado === 'pedido' ?
                                { color: '#FF872c', fontWeight: 'bold' } :
                                null
                        }
                        to="/pedido" className="sistema-header-nav-link">
                        Ordem de serviço
                    </Link>

                    <Link
                        style={
                            this.props.selecionado === 'parceirosDeNegocio' ?
                                { color: '#FF872c', fontWeight: 'bold' } :
                                null
                        }
                        to="/cliente" className="sistema-header-nav-link">
                        Parceiros de negocios
                    </Link>

                    <Link
                        style={
                            this.props.selecionado === 'financeiro' ?
                                { color: '#FF872c', fontWeight: 'bold' } :
                                null
                        }
                        to="/financeiro" className="sistema-header-nav-link">
                        Financeiro
                    </Link>

                    <Link
                        style={
                            this.props.selecionado === 'perfil' ?
                                { color: '#FF872c', fontWeight: 'bold' } :
                                null
                        }
                        to="/perfil" className="sistema-header-nav-link">
                        Perfil
                    </Link>
                </nav>

                <nav className='sistema-header-nav-direita'>
                    <Link onClick={()=>this.removerUsuario()} to="/login" className="sistema-header-nav-link">
                        Sair
                    </Link>
                </nav>
            </header>
        )
    }
}