import React, { Component } from 'react';
import computadores from '../../../../assets/image/computadorpromo.png';
import fundo from '../../../../assets/image/slideimagem.jpg';
import './slide.css';

export default class Slide extends Component {
    render() {
        return (
            <section className='slide-container'>
                <div className='slide-wrapper'>
                    <section className='slide-section'>
                        <h1 className='slide-titulo'>Facilite sua vida</h1>
                        <p className='slide-texto'>Encontre um instalador capacitado bem próximo de você para realizar o seu serviço, agora você não precisa mais se preocupar, pois só escolhemos os melhores!</p>
                    </section>
                    <img src={computadores} alt="computador, tablet, celular" className='slide-imagem-promo' />
                </div>
                <img src={fundo} alt="imagem de fundo" className='slide-imagem-fundo' />
            </section>
        )
    }
}