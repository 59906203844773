import React, { Component } from 'react';
import './prova-social.css';
import pessoa1 from '../../../../assets/image/pessoa1.png';
import pessoa2 from '../../../../assets/image/pessoa2.png';
import pessoa3 from '../../../../assets/image/pessoa3.jpeg';

export default class ProvaSocial extends Component {
    render() {
        return (
            <section id='prova-social-container'>
                <section className='prova-social-wrapper'>
                    <div className='prova-social-conteudo-container'>
                        <div className='prova-social-imagem-wrapper'>
                            <img src={pessoa1} alt="clientes" className='prova-social-imagem'/>
                        </div>
                        <h1>Sérgio cardoso</h1>
                        <i>
                            <p className='prova-social-conteudo'>
                                "A IEX facilitou muito a minha vida, agora eu não tenho mais dor de cabeça para contratar profissionais para instalação dos eletrodomésticos"
                            </p>
                        </i>
                    </div>

                    <div className='prova-social-conteudo-container'>
                        <div className='prova-social-imagem-wrapper'>
                            <img src={pessoa2} alt="clientes" className='prova-social-imagem'/>
                        </div>
                        <h1>Marcelo de Souza</h1>
                        <i>
                            <p className='prova-social-conteudo'>
                                "Depois que comecei a usar a IEX nunca mais tive dificuldade para trabalhar. Sempre fico com uma agenda lotada de clientes querendo uma instalação."
                            </p>
                        </i>
                    </div>

                    <div className='prova-social-conteudo-container'>
                        <div className='prova-social-imagem-wrapper'>
                            <img src={pessoa3} alt="clientes" className='prova-social-imagem'/>
                        </div>
                        <h1>Luciana Ramos</h1>
                        <i>
                            <p className='prova-social-conteudo'>
                                "A IEX me deu a segurança de poder contratar profissionais altamente qualificados para instalações dos meus eletrodomésticos"
                            </p>
                        </i>
                    </div>
                </section>
            </section>
        )
    }
}