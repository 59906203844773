import React, { Component } from 'react';
import './sidebar.css';
import logo from '../../../../assets/image/logo.png';

export default class Sidebar extends Component {
    render() {
        return (
            <>
            <aside className={`sidebar-container ${this.props.bgi}`}>
                {this.props.children}
            </aside>
            </>
        )
    }
}