import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import UsuarioService from 'lirida-main-js-service/service/Usuario/UsuarioService';
import React, { Component } from 'react';
import Util from '../../../util/Util';
import Container from '../sistema-components/Container/Container';
import Header from '../sistema-components/Header/Header';
import Sidebar from '../sistema-components/Sidebar/Sidebar';
import provisoria from '../../../assets/image/logo.png';
import MsgOK from '../../../component/MsgOK/MsgOK';
import MsgError from '../../../component/MsgError/MsgError';
import '../sistema.css';
import Input from '../../../component/Input/Input';

export default class Perfil extends Component {
    util = new Util();
    usuarioService = new UsuarioService();

    state = {
        usuario: new Usuario(),
        filtro: 'usuario',
        senhaAtual: '',
        novaSenha: '',
        confirmarNovaSenha: '',
        msgOKVisivel: false,
        msgErrorVisivel: false,
        mensagem: '',
        carregando: false,
    }

    async componentDidMount() {
        let usuario = await this.util.buscarLocalStorage('USUARIO');
        this.setState({ usuario: usuario });
        console.log(usuario);
    }

    async alterarNome() {
        this.setState({
            carregando: false,
            msgOKVisivel: false,
            msgErrorVisivel: false,
        })

        let usuario = this.state.usuario;
        let retorno = await this.usuarioService.salvar(usuario);

        if (retorno.resultado === true) {
            this.util.salvarLocalStorage('USUARIO', usuario);
            this.setState({
                msgOKVisivel: true,
                mensagem: retorno.msg
            })
        }
    }

    async alterarSenha(pSubmit) {
        pSubmit.preventDefault();
        this.setState({
            carregando: true,
            msgOKVisivel: false,
            msgErrorVisivel: false
        });

        let usuario = this.state.usuario;

        if (this.state.senhaAtual === usuario.senha) {
            usuario.senha = this.state.novaSenha;
            let retorno = await this.usuarioService.salvar(usuario);
            console.log(retorno);
            this.setState({
                msgOKVisivel: true,
                mensagem: retorno.msg
            })
        } else {
            this.setState({
                msgErrorVisivel: true,
                mensagem: 'A senha atual digitada não é igual à sua senha de acesso'
            })
        }
    }

    render() {
        return (
            <Container>
                <Header selecionado="perfil" />
                <div className='sistema-wrapper-lista'>

                    <Sidebar bgi="bgi-perfil">
                        {this.state.filtro === 'usuario' ?
                            <button className="btn-lg btn-com-fundo">Usuário</button> :
                            <button
                                className="btn-lg btn-secundario"
                                onClick={() => this.setState({
                                    filtro: 'usuario',
                                    msgOKVisivel: false,
                                    msgErrorVisivel: false,
                                })}>Usuário</button>
                        }
                        {this.state.filtro === 'alterar senha' ?
                            <button className="btn-lg btn-com-fundo">Alterar senha</button> :
                            <button
                                className="btn-lg btn-secundario"
                                onClick={() => this.setState({
                                    filtro: 'alterar senha',
                                    msgOKVisivel: false,
                                    msgErrorVisivel: false,
                                })}>Alterar senha</button>
                        }
                    </Sidebar>
                    <div className='sistema-conteudo-wrapper'>
                        {this.state.filtro === 'usuario' ?
                            <section className='sistema-perfil-wrapper'>
                                <MsgError
                                    msg={this.state.mensagem}
                                    visivel={this.state.msgErrorVisivel} />
                                <MsgOK
                                    msg={this.state.mensagem}
                                    visivel={this.state.msgOKVisivel} />
                                <div className='sistema-img-perfil-wrapper'>
                                    <img src={provisoria} alt='img perfil' className='sistema-img-perfil' />
                                </div>
                                <Input
                                    titulo="Alterar foto"
                                    type="file"
                                />

                                <Input
                                    titulo="Nome"
                                    value={this.state.usuario.nome}
                                    onChange={pTexto => this.setState({
                                        usuario: {
                                            ...this.state.usuario,
                                            nome: pTexto.target.value
                                        }
                                    })}
                                />

                                <div>
                                    <h1>Email</h1>
                                    <p>{this.state.usuario.email}</p>
                                </div>

                                <button
                                    onClick={() =>
                                        this.alterarNome()}
                                    className='btn btn-com-fundo'
                                >Salvar</button>

                            </section>
                            :
                            this.state.filtro === 'alterar senha' ?
                                <form
                                    onSubmit={pSubmit => this.alterarSenha(pSubmit)}
                                    className='sistema-perfil-wrapper'>

                                    <MsgError
                                        msg={this.state.mensagem}
                                        visivel={this.state.msgErrorVisivel} />
                                    <MsgOK
                                        msg={this.state.mensagem}
                                        visivel={this.state.msgOKVisivel} />

                                    <Input
                                        titulo="Senha atual"
                                        type="password"
                                        onChange={pTexto => this.setState({ senhaAtual: pTexto.target.value })}
                                        value={this.state.senhaAtual}
                                        required
                                    />
                                    <Input
                                        titulo="Nova senha"
                                        type="password"
                                        onChange={pTexto => this.setState({ novaSenha: pTexto.target.value })}
                                        value={this.state.novaSenha}
                                        required
                                    />
                                    <Input
                                        titulo="Confirmar nova senha"
                                        type="password"
                                        onChange={pTexto => this.setState({ confirmarNovaSenha: pTexto.target.value })}
                                        value={this.state.confirmarNovaSenha}
                                        required
                                    />
                                    <input
                                        value="Alterar"
                                        type="submit"
                                        className='btn btn-com-fundo'
                                    />
                                </form>
                                :
                                null}
                    </div>
                </div>
            </Container>
        )
    }
}