import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import PessoaService from 'lirida-main-js-service/service/Pessoa/PessoaService';
import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import UsuarioService from 'lirida-main-js-service/service/Usuario/UsuarioService';
import React, { Component } from 'react';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import logo from '../../../../assets/image/logo.png';
import './login.css';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import MsgError from '../../../../component/MsgError/MsgError';
import Input from '../../../../component/Input/Input';
import Loading from '../../../../component/Loading/Loading';



export default class Login extends Component {
    usuarioService = new UsuarioService();
    pessoaService = new PessoaService();
    util = new Util();
    constante = new Constante();

    state = {
        usuario: '',
        senha: '',
        liberarAcessoPedido: false,
        mensagemVisivel: false,
        mensagem: '',
        carregando: false,
    }

    async validarAcesso(pSubmit) {
        pSubmit.preventDefault();
        this.setState({carregando: true});

        if (!this.state.usuario || !this.state.senha) {
            return;
        }

        let retornoUsuario = await this.usuarioService.validarAcesso(this.state.usuario, this.state.senha);
        console.log(retornoUsuario);

        let pessoa;
        let usuario = new Usuario();
        usuario = retornoUsuario.objeto;

        if (retornoUsuario.resultado === false) {
            return this.setState({
                usuario: '',
                senha: '',
                mensagemVisivel: true,
                mensagem: retornoUsuario.msg,
                carregando: false
            })
        }

        if (retornoUsuario.resultado === true) {
            let retornoPessoa = await this.pessoaService.buscarPorEmail(
                this.state.usuario,
                this.constante.seqUsuario
            );

            console.log(retornoPessoa)

            if (retornoPessoa.resultado === false) {
                pessoa = new Pessoa();

                pessoa.seqUsuario = this.constante.seqUsuario;
                pessoa.nome = usuario.nome;
                pessoa.situacao = "ATIVO";
                pessoa.emailPlataformaLirida = usuario.email;
                pessoa.tipoDocumento = "CNPJ";
                pessoa.documento = usuario.documento;
                pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfil;
                pessoa.seqTabelaPreco = this.constante.seqTabelaPreco;

                await this.pessoaService.salvar(pessoa);
            } else {
                pessoa = retornoPessoa.objeto;
            }

            let retorno = await this.util.buscarUrlBaseImagemItem();

            console.log(retorno);

            this.util.salvarLocalStorage('URL_BASE_IMAGEM_ITEM', retorno);
            this.util.salvarLocalStorage("USUARIO", usuario);
            this.util.salvarLocalStorage("PESSOA", pessoa);

            this.setState({carregando: false, liberarAcessoPedido: true });
        }
    }

    render() {
        return (
            <div className='login-container'>
                {this.state.liberarAcessoPedido ? <Navigate to="/dashboard" /> : null}

                <Link to="/principal">
                    <img src={logo} alt="onework" className='login-logo-principal' />
                </Link>

                <div className='login-wrapper'>
                    <aside className="login-imagem-principal-container" />
                    <form
                        onSubmit={pSubmit => this.validarAcesso(pSubmit)}
                        className='login-input-wrapper'>
                        <h1 className='login-titulo'>Login</h1>
                        <Input
                            titulo='Usuário'
                            tam="grande"
                            type="email"
                            value={this.state.usuario}
                            onChange={pTexto => this.setState({ usuario: pTexto.target.value })}
                            required
                        />
                        <Input
                            titulo="Senha"
                            tam="grande"
                            value={this.state.senha}
                            onChange={pTexto => this.setState({ senha: pTexto.target.value })}
                            type="password"
                            required
                        />
                        <div className='login-links-wrapper'>
                            <Link to="/recuperar-senha" className="link-esqueci-senha">Esqueci minha senha</Link>
                            {this.state.carregando ? <Loading /> : <input
                                value="Entrar"
                                type="submit"
                                className="btn-lg btn-com-fundo"
                            />}
                            <p className='paragrafo-link-cadastro'>Não possui uma conta? <Link to="/cadastro">Cadastre-se</Link></p>
                        </div>
                        <MsgError
                            visivel={this.state.mensagemVisivel}
                            msg={this.state.mensagem}
                        />
                    </form>
                </div>
            </div>
        )
    }
}