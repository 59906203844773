import React, { Component } from 'react';
import './dropdown.css';

export default class Dropdown extends Component {
    render() {
        return (
            this.props.visivel ?
                <div className='dropdown-container'>
                    {this.props.children}
                </div>
                : null
        )
    }
}