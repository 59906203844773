import React, { Component } from 'react';
import './msgerror.css';

export default class MsgError extends Component {
    render() {
        return (
            this.props.visivel ?
                <div className='msgerror-container'>
                    {this.props.msg}
                </div> :
                null
        )
    }
}