import { Component } from "react"
import './SecaoCheckoutStyles.css'

export default class SecaoCheckout extends Component {
    render() {
        return (
            <div className='container-secao-form-checkout'>

                <h1> {this.props.titulo} </h1>
                <div className='secao-form-inputs-checkout'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


