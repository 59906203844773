import UsuarioService from 'lirida-main-js-service/service/Usuario/UsuarioService';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Input from '../../../../../component/Input/Input';
import Loading from '../../../../../component/Loading/Loading';
import MsgError from '../../../../../component/MsgError/MsgError';
import PopUp from '../../../../../component/PopUp/PopUp';
import Constante from '../../../../../util/Constante';
import './recuperar-senha.css';

export default class RecuperarSenha extends Component {
    usuarioService = new UsuarioService();
    constante = new Constante();

    state = {
        email: "",
        liberarRetornoLogin: false,
        mensagemVisivel: false,
        mensagem: '',
        carregando: false,
    };

    async recuperarSenha(pSubmit) {
        pSubmit.preventDefault();
        this.setState({carregando: true});

        let retorno = await this.usuarioService.recuperarSenha(this.state.email, this.constante.seqUsuario);
        console.log(retorno);

        if (retorno.resultado === false) {
            this.setState({
                mensagemVisivel: true,
                mensagem: retorno.msg,
            })
            return;
        }

        this.setState({
            popupVisivel: true,
            mensagem: retorno.msg,
            carregando: false
        });
    }


    render() {
        return (
            <div className='recuperarsenha-container'>
                <PopUp
                    visivel={this.state.popupVisivel}
                    msg={this.state.mensagem}
                    aoConfirmar={() => this.setState({ liberarRetornoLogin: true })}
                    txtConfirmar="OK"
                />

                {this.state.liberarRetornoLogin ? <Navigate to="/login" /> : null}

                <div className='recuperarsenha-wrapper'>
                    <aside className="recuperarsenha-imagem-principal-container" />
                    <form
                        onSubmit={pSubmit => this.recuperarSenha(pSubmit)}
                        className='recuperarsenha-input-wrapper'>
                        <h1 className='recuperarsenha-titulo'>Recuperar senha</h1>
                        <Input
                            titulo="Email"
                            type="email"
                            tam="grande"
                            value={this.state.email}
                            onChange={pTexto => this.setState({ email: pTexto.target.value })}
                            required
                        />
                        <div className='recuperarsenha-links-wrapper'>
                        {this.state.carregando ? <Loading /> : <input
                                value="Recuperar"
                                type="submit"
                                className="btn-lg btn-com-fundo"
                            />}
                            <p className='paragrafo-link-login'> <Link to="/login">Lembrei minha senha</Link></p>
                        </div>
                        <MsgError
                            visivel={this.state.mensagemVisivel}
                            msg={this.state.mensagem}
                        />
                    </form>
                </div>
            </div>
        )
    }
}