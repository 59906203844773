import React, { Component } from 'react';
import Container from '../sistema/sistema-components/Container/Container';
import logo from '../../assets/image/logo.png';
import './checkoutstyles.css'
import Input from '../../component/Input/Input';
import SecaoCheckout from '../../component/SecaoCheckout/SecaoCheckout';
import Pessoa from 'lirida-main-js-service/service/Pessoa/Pessoa';
import Util from 'lirida-main-js-service/service/Util/Util';
import Contato from 'lirida-main-js-service/service/Contato/Contato';
import PessoaService from 'lirida-main-js-service/service/Pessoa/PessoaService';
import Constante from '../../util/Constante';
import Endereco from 'lirida-main-js-service/service/Endereco/Endereco';

export default class Checkout extends Component {
    util = new Util();
    pessoaService = new PessoaService();
    constante = new Constante();

    state = {
        pessoa: new Pessoa(),
        contato: new Contato(),
        endereco: new Endereco(),
    }

    async salvar() {
        let pessoa = this.state.pessoa;
        pessoa.seqUsuario = this.constante.seqUsuario
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfil
        let retorno = await this.pessoaService.salvar(pessoa);
        console.log(retorno);
    }

    async salvarEndereco(){
        let endereco = this.state.endereco
        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = this.state.pessoa.seqPessoa;
        let retorno = await this.pessoaService.salvarEndereco(endereco);
        console.log(retorno);
        return;
    }
    render() {
        return (
            <Container>
                <div className='div-imagem-checkout'>
                    <img src={logo} alt="logo iex brasil" />
                    <h1>CHECKOUT</h1>
                </div>


                <div className='container-form-checkout'>
                    {/* DADOS CL1IENTE */}
                    <SecaoCheckout
                        titulo='Dados cliente:'>
                        <Input
                            titulo='Nome completo'
                            type='text'
                            onChange={(pTexto) => this.setState({
                                pessoa: {
                                    ...this.state.pessoa, nome: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='CPF/CNPJ'
                            type='text'
                            onChange={(pTexto) => this.setState({
                                pessoa: {
                                    ...this.state.pessoa, documento: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='Telefone celular'
                            type='tel'
                            onChange={(pTexto) => this.setState({
                                contato: {
                                    ...this.state.contato, contato: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='Telefone whatsApp'
                            type='tel'
                            onChange={(pTexto) => this.setState({
                                contato: {
                                    ...this.state.contato, contato: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='Email'
                            type='email'
                            onChange={(pTexto) => this.setState({
                                pessoa: {
                                    ...this.state.pessoa, emailPlataformaLirida: pTexto.target.value
                                }
                            })}
                        />
                        <div className='secao-form-botao-container'>
                            <button className='btn btn-com-fundo'
                                onClick={() => this.salvar()}>Salvar</button>
                        </div>

                    </SecaoCheckout>



                            {/* ENDEREÇO */}
                    <SecaoCheckout
                        titulo='Local de serviço:'>
                        <Input
                            titulo='Logradouro'
                            type='text'
                            onChange={(pTexto) => this.setState({
                                endereco: {
                                    ...this.state.endereco, logradouro: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='Bairro'
                            type='text'
                            onChange={(pTexto) => this.setState({
                                endereco: {
                                    ...this.state.endereco, bairro: pTexto.target.value
                                }
                            })}
                        />
                        <Input
                            titulo='Complemento'
                            type='text'
                            onChange={(pTexto) => this.setState({
                                endereco: {
                                    ...this.state.endereco.complemento, Complemento: pTexto.target.value
                                }
                            })}
                        />

                        <section>
                            <label>Data ideal</label>
                            <br />
                            <input
                                type="date"
                            />
                        </section>

                        <div className='secao-form-botao-container'>
                            <button className='btn btn-com-fundo'
                                onClick={() => this.salvarEndereco()}>Salvar</button>
                        </div>

                    </SecaoCheckout>

                </div>
            </Container >
        )
    }
}