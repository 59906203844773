import React, { Component } from 'react';
import celular from '../../../../assets/image/celularpromo.png';
import './funcionalidade.css';

export default class Funcionalidade extends Component {
    render() {
        return (
            <section id='funcionalidade-container'>
                <div className='funcionalidade-wrapper'>
                <img src={celular} alt="celular" className='funcionalidade-imagem' />
                <img src={celular} alt="celular" className='funcionalidade-imagem2' />
                    <h1 className='funcionalidade-titulo'>Funcionalidade</h1>

                    <p className='funcionalidade-texto funcionalidade-texto1'>Tenha técnicos credenciados e de alta qualificação para sua instalação.</p>

                    <p className='funcionalidade-texto funcionalidade-texto2'>Nunca mais fique na mão. Encontre os melhores técnicos na sua região. Tenha garantia de qualidade na instalação</p>

                    <p className='funcionalidade-texto funcionalidade-texto3'>A solução que você precisava. Pensado pra você. Feito pra você</p>
                </div>
            </section>
        )
    }
}