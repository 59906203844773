import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Hotsite from './pages/hotsite/Hotsite';
import Login from './pages/hotsite/hotsite-components/Login/Login';
import Cadastro from './pages/hotsite/hotsite-components/Login/Cadastro/Cadastro';
import RecuperarSenha from './pages/hotsite/hotsite-components/Login/RecuperarSenha/RecuperarSenha';
import Cliente from './pages/sistema/Pages/Cliente';
import Pedido from './pages/sistema/Pages/Pedido';
import Perfil from './pages/sistema/Pages/Perfil';
import Dashboard from './pages/sistema/Pages/Dashboard';
import Financeiro from './pages/sistema/Pages/Financeiro';
import ResumoOS from './pages/sistema/Pages/ResumoOS';
import Checkout from './pages/checkout/Checkout';
import TelaMapa from './pages/telaMapa/telaMapa';

export default class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />

                    <Route index path="/" element={<TelaMapa />} />
                    {/* <Route index path="/" element={<Hotsite />} /> */}
                    <Route path="login" element={<Login />} />
                    <Route path="recuperar-senha" element={<RecuperarSenha />} />
                    <Route path="cadastro" element={<Cadastro />} />
                    <Route path="cliente" element={<Cliente />} />
                    <Route path="pedido" element={<Pedido />} />
                    <Route path="financeiro" element={<Financeiro />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="perfil" element={<Perfil />} />
                    <Route path="resumo-os" element={<ResumoOS />} />
                    <Route path="iex-checkout" element={<Checkout />} />                    
                    {/* <Route path="mapa" element={<TelaMapa />} />                     */}
                </Routes>
            </BrowserRouter>
        )
    }
}