import React, { Component } from 'react';
import './cadastro.css';
import { Link, Navigate } from 'react-router-dom';
import UsuarioService from 'lirida-main-js-service/service/Usuario/UsuarioService';
import Constante from '../../../../../util/Constante';
import Usuario from 'lirida-main-js-service/service/Usuario/Usuario';
import PopUp from '../../../../../component/PopUp/PopUp';
import Input from '../../../../../component/Input/Input';
import Util from '../../../../../util/Util';
import Loading from '../../../../../component/Loading/Loading';

export default class Cadastro extends Component {
    usuarioService = new UsuarioService();
    constante = new Constante();
    util = new Util();

    state = {
        usuario: new Usuario(),
        liberarRetornoLogin: false,
        popupVisivel: false,
    };

    async salvarNovoUsuario(pSubmit) {
        pSubmit.preventDefault();
        this.setState({carregando: true});

        let usuario = this.state.usuario;
        usuario.tipoPessoa = "PJ";
        usuario.seqUsuarioOrigem = this.constante.seqUsuario;
        console.log(usuario);

        let res = await this.usuarioService.salvarNovoUsuario(usuario);

        this.setState({carregando: false});
        
        if (res.resultado === true) {
            this.setState({
                popupVisivel: true,
                mensagem: res.msg
            });
        }
    }

    setAsyncState(newState) {
        return new Promise((resolve) => this.setState(newState, resolve));
    }

    formatarCPFCNPJ(pTexto) {
        this.setState({
            usuario: {
                ...this.state.usuario,
                documento: this.util.formatarCPFCNPJ(pTexto)
            }
        })
    }


    render() {
        return (
            <div className='cadastro-container'>

                <PopUp
                    visivel={this.state.popupVisivel}
                    msg={this.state.mensagem}
                    aoConfirmar={() => this.setState({
                        popupVisivel: false,
                        liberarRetornoLogin: true,
                    })}
                    txtConfirmar="OK"
                />

                {this.state.liberarRetornoLogin ? <Navigate to="/login" /> : null}

                <div className='cadastro-wrapper'>
                    <aside className="cadastro-imagem-principal-container" />
                    <form
                        onSubmit={pSubmit => this.salvarNovoUsuario(pSubmit)}
                        className='cadastro-input-wrapper'>
                        <h1 className='cadastro-titulo'>Cadastre-se</h1>
                        <Input
                            titulo="Nome"
                            type="text"
                            tam="grande"
                            onChange={pTexto => this.setState({
                                usuario: {
                                    ...this.state.usuario,
                                    nome: pTexto.target.value
                                }
                            })}
                            value={this.state.usuario.nome}
                            required
                        />
                        <Input
                            titulo="Email"
                            type="email"
                            tam="grande"
                            onChange={pTexto => this.setState({
                                usuario: {
                                    ...this.state.usuario,
                                    email: pTexto.target.value
                                }
                            })}
                            value={this.state.usuario.email}
                            required
                        />
                        <Input
                            titulo="Documento (CPF / CNPJ)"
                            tam="grande"
                            onChange={pTexto => this.formatarCPFCNPJ(pTexto.target.value)}
                            value={this.state.usuario.documento}
                            required
                        />
                        <div className='cadastro-links-wrapper'>
                        {this.state.carregando ? <Loading /> : <input
                                value="Cadastrar"
                                type="submit"
                                className="btn-lg btn-com-fundo"
                            />}
                            <p className='paragrafo-link-login'>Já possui uma conta? <Link to="/login">Entrar</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}