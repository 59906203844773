import React, { Component } from 'react';
import Convite from './hotsite-components/Convite/Convite';
import Footer from './hotsite-components/Footer/Footer';
import Funcionalidade from './hotsite-components/Funcionalidade/Funcionalidade';
import Header from './hotsite-components/Header/Header';
import Parceiros from './hotsite-components/Parceiros/Parceiros';
import ProvaSocial from './hotsite-components/ProvaSocial/ProvaSocial';
import Slide from './hotsite-components/Slide/Slide';
import Sobre from './hotsite-components/Sobre/Sobre';

export default class Hotsite extends Component {

    state = {
        verMais_sobre: false,
    }

    render() {
        return (
            <>
                <Header />
                <Slide />
                <Sobre />
                <Funcionalidade />
                <ProvaSocial />
                <Parceiros />
                <Convite />
                <Footer />
            </>
        )
    }
}