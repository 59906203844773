import React, { Component } from 'react';
import Container from '../sistema-components/Container/Container';
import Header from '../sistema-components/Header/Header';
import Sidebar from '../sistema-components/Sidebar/Sidebar';
import '../sistema.css';

export default class Dashboard extends Component {
    render() {
        return (
            <Container>
                <Header selecionado="dashboard" />
                <div className='sistema-wrapper-lista'>
                    <Sidebar bgi="bgi-dashboard">

                    </Sidebar>
                    <div className='sistema-conteudo-wrapper'>
                        <div className='sistema-grafico-container'>
                            <div className='sistema-grafico-wrapper grafico-lg'>Nº de pedidos</div>
                            <div className='sistema-grafico-wrapper'>Nº de pedidos</div>
                            <div className='sistema-grafico-wrapper grafico-sm'>Nº de pedidos</div>
                            <div className='sistema-grafico-wrapper'>Nº de pedidos</div>
                            <div className='sistema-grafico-wrapper'>Nº de pedidos</div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}