import Util_api from 'lirida-main-js-service/service/Util/Util';

export default class Util {
    util_api = new Util_api();

    async buscarUrlBaseImagemItem() {
        let retorno = await this.util_api.buscarUrlBaseImagemItem();
        retorno = await retorno.json();
        return retorno;
    }

    async buscarLocalStorage(pCampo) {
        let retorno = localStorage.getItem(pCampo);
        retorno = await JSON.parse(retorno);
        console.log(retorno);
        return retorno;
    }

    salvarLocalStorage(pCampo, pValor) {
        let valor = JSON.stringify(pValor);
        localStorage.setItem(pCampo, valor);
        console.log(pCampo + ' salvo localstorage com sucesso')
    }

    async removerLocalStorage(pCampo) {
        let retorno = localStorage.removeItem(pCampo);
        return retorno;
    }

    formatarCPFCNPJ(pTexto) {
        let t = pTexto;

        if (t.length > 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }

        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        }
    }

    async buscarCep(pCep) {
        let retorno = await fetch('https://viacep.com.br/ws/'+ pCep + '/json')
        retorno = await retorno.json();

        return retorno;
    }
}