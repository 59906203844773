import React, { Component } from 'react';
import './parceiros.css';
import logolg from '../../../../assets/image/logolg.png';
import logoelgin from '../../../../assets/image/logoelgin.png';

export default class Parceiros extends Component {
    render() {
        return (
            <section id='parceiros-container'>
                <section className='parceiros-wrapper'>
                    <h1 className='parceiros-titulo'>Parceiros</h1>

                    <div className='parceiros-imagem-container'>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logolg} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logoelgin} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logolg} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logoelgin} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logolg} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logoelgin} alt="parceiro" className='parceiros-imagem' />
                        </div>
                        <div className='parceiros-imagem-wrapper'>
                            <img src={logolg} alt="parceiro" className='parceiros-imagem' />
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}