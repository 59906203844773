import React, { Component } from 'react';
import './msgok.css';

export default class MsgOK extends Component {
    render() {
        return (
            this.props.visivel ?
                <div className='msgok-container'>
                    {this.props.msg}
                </div> :
                null
        )
    }
}